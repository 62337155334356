import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// import { createTheme } from '@material-ui/core/styles';

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import red from '@material-ui/core/colors/red';
// import green from "@material-ui/core/colors/green";
// import teal from "@material-ui/core/colors/teal";

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#1f2020",
    },
    primary: {
      light: "#18C7CD",
      main: "#18C7CD",
      dark: "#18C7CD",
      background: "#123649",
      contrastText: "#fff",
    },
    secondary: {
      light: "#123649",
      main: "#123649",
      dark: "#123649",
      contrastText: "#FFF",
    },
  },
});

// Color Setup
// https://material-ui.com/customization/color/#playground

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById("root")
);
